import React from "react";
import { usePopup } from "../../contexts/popupContext";

export default function PopupTour1Day1() {
  const { showTour1Day1, setShowTour1Day1 } = usePopup();
  return (
    <>
      {showTour1Day1 ? (
        <div className="overlay">
          <div className="max-w-[1022px] w-full relative mb-[50px] top-[20px] left-0 md:left-[50%] md:translate-x-[-50%] popup-days --two">
            <div className="bg-red p-[16px] flex items-center justify-between">
              <p className="text-[12px] md:text-[15px] font-bold text-white">
                NGÀY 1: VIỆT NAM - SINGAPORE
              </p>
              <span
                onClick={() => setShowTour1Day1(false)}
                className="cursor-pointer"
              >
                <img srcSet="/icons/popup-close.png 2x" alt="" />
              </span>
            </div>
            <div>
              <div className="flex p-[18px] bg-[#f1f1f1] h-[85vh] popup-tour-wrapper">
                <div className="flex-shrink-0 w-[455px] md:block hidden h-full">
                  <img
                    className="object-cover w-full h-full"
                    srcSet="/images/genting-tour1-1.png 4x"
                    alt=""
                  />
                </div>
                <div className="bg-white p-[15px] flex-1 h-full overflow-auto popup-tour-content">
                  <div className="pb-[16px] mb-[16px] flex items-baseline gap-[5px] border-b-[3px] border-dotted border-[#f1f1f1]">
                    <div>
                      <img srcSet="/icons/map-marker.png 2x" alt="" />
                    </div>
                    <div>
                      <p className="text-[12px] md:text-[20px] text-grey3F font-bold">
                        VIỆT NAM - SINGAPORE
                      </p>
                      <p className="text-[12px] italic">
                        (Ăn trưa & ăn tối)
                      </p>
                    </div>
                  </div>
                  <div className="px-3" dangerouslySetInnerHTML={{__html: `
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;"><strong>06h00:</strong> Quý khách có mặt tại sân bay làm thủ tục đáp chuyến bay dự kiến <span style="background-color:#ffffff;color:#0000ff;font-size:14px;"><strong>VN 651 SGNSIN (08:55 – 12:10) tới Singapore.</strong></span></span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;"><strong>12h10:</strong> Xe đưa Quý khách dùng bữa trưa tại nhà hàng địa phương. Sau đó khởi hành tham quan:</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;"><strong>- Garden by the Bay:&nbsp;</strong>Đây là một khu vườn rừng nhân tạo rộng hơn 100ha và hơn 200.000 loài thực vật quý hiếm.&nbsp;</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;"><strong>- Chùa Răng Phật Singapore</strong> được xem là bảo tàng nghệ thuật Phật giáo lớn nhất tại đảo quốc Singapore.</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Sau bữa tối, đoàn về khách sạn nhận phòng và nghỉ ngơi.</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Tự do khám phá thành phố hoặc tham gia Option tour về đêm (chi phí tự túc) như:</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Thưởng thức chương trình nhạc nước<strong> “SPECTRA A LIGHT AND WATER SHOW“</strong> kết hợp với tham quan khu thương mại phức hợp Suntec city – đài phun nước thịnh vượng với 5 tòa nhà tượng trưng cho Ngũ Hành, ngồi thuyền dạo chơi trên sông Singapore ngắm tượng Merlion rực rỡ sắc màu.</span></p>
`}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
