import React from "react";
import { usePopup } from "../../contexts/popupContext";

export default function PopupTour1Day3() {
  const { showTour1Day3, setShowTour1Day3 } = usePopup();
  return (
    <>
      {showTour1Day3 ? (
        <div className="overlay">
          <div className="max-w-[1022px] w-full relative mb-[50px] top-[20px] left-0 md:left-[50%] md:translate-x-[-50%] popup-days --two">
            <div className="bg-red p-[16px] flex items-center justify-between">
              <p className="text-[12px] md:text-[15px] font-bold text-white">
                NGÀY 3: SINGAPORE – KUALA LUMPUR (MALAYSIA)
              </p>
              <span
                onClick={() => setShowTour1Day3(false)}
                className="cursor-pointer"
              >
                <img srcSet="/icons/popup-close.png 2x" alt="" />
              </span>
            </div>
            <div>
              <div className="flex p-[18px] bg-[#f1f1f1] h-[85vh] popup-tour-wrapper">
                <div className="flex-shrink-0 w-[455px] md:block hidden h-full">
                  <img
                    className="object-cover w-full h-full"
                    srcSet="/images/popup-tour1-3.png 4x"
                    alt=""
                  />
                </div>
                <div className="bg-white p-[15px] flex-1 h-full overflow-auto popup-tour-content">
                  <div className="pb-[16px] mb-[16px] flex items-baseline gap-[5px] border-b-[3px] border-dotted border-[#f1f1f1]">
                    <div>
                      <img srcSet="/icons/map-marker.png 2x" alt="" />
                    </div>
                    <div>
                      <p className="text-[12px] md:text-[20px] text-grey3F font-bold">
                      SINGAPORE – KUALA LUMPUR (MALAYSIA)
                      </p>
                      <p className="text-[12px] italic">
                      (Ăn sáng, trưa & tối)
                      </p>
                    </div>
                  </div>
                  <div className="px-3" dangerouslySetInnerHTML={{__html: `
                  <p><span style="background-color:#ffffff;color:#131313;font-size:14px;">Quý khách dậy sớm ngắm bình minh trên du thuyền, tự do ăn sáng tại các nhà hàng.</span></p>
                  <p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>08h00</strong> Du thuyền cập cảng Malaysia, xe đón đoàn tại cảng và đưa về thủ đô Kuala Lumpur, tham quan</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+ Chụp hình lưu niệm tại&nbsp;<strong>Tháp Đôi (Twin Towers)</strong>.</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+<strong> Cung điện Hoàng gia (Royal Palace):</strong> Cung điện được xây dựng trên ngọn đồi Jalan Duta phía tây bắc bắc Kuala Lumpur.&nbsp;</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+<strong> Đài tượng niệm Quốc gia (National Monument)</strong> - một trong những bức tượng đồng lớn nhất thế giới, cao 15.5m. Để tưởng niệm 10.000 đã ngã xuống trong chiến tranh thế giới thứ I và thứ II&nbsp;</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+<strong> Quảng trường Độc lập (Independence Square).</strong></span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+ Tham quan&nbsp;<strong>Cửa hàng Socola - Bery's</strong> &amp;<strong> đặc sản địa phương</strong></span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Quý khách dùng bữa trưa tại nhà hàng địa phương.</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;"><strong>13h30 Xe đưa đoàn về lại Du Thuyền.</strong></span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Quý khách có thể ngâm mình trong làn nước mát mẻ hoặc tham gia hoạt động thể thao thú vị:</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+ <strong>CÔNG VIÊN NƯỚC TRÊN BIỂN:&nbsp;</strong>Công viên nước trên đại dương với 6 đường trượt phù hợp cho gia đình và cả những người ưa mạo hiểm với niềm vui bất tận.</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+ <strong>NHIỀU LỰA CHỌN ẨM THỰC:&nbsp;</strong>tổng cộng 35 nhà hàng và bar ngay cả những yêu cầu về ẩm thực đặc biệt như đồ chay, đồ Ấn Độ. Du thuyền phục vụ ẩm thực Trung Quốc, Nhật Bản, Hàn Quốc, các món ăn Đông Nam Á và rất nhiều loại đồ uống tuyệt vời.</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Quý khách thưởng thức<strong> SHOW BIỂU DIỄN</strong> đặc sắc tại nhà hát lớn trên Du Thuyền.</span></p>
                  `}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
