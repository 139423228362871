import React from "react";
import { usePopup } from "../../contexts/popupContext";

export default function PopupTour1Day2() {
  const { showTour1Day2, setShowTour1Day2 } = usePopup();
  return (
    <>
      {showTour1Day2 ? (
        <div className="overlay">
          <div className="max-w-[1022px] w-full relative mb-[50px] top-[20px] left-0 md:left-[50%] md:translate-x-[-50%] popup-days --two">
            <div className="bg-red p-[16px] flex items-center justify-between">
              <p className="text-[12px] md:text-[15px] font-bold text-white">
                NGÀY 2: SINGAPORE – CHECK IN GENTING DREAM
              </p>
              <span
                onClick={() => setShowTour1Day2(false)}
                className="cursor-pointer"
              >
                <img srcSet="/icons/popup-close.png 2x" alt="" />
              </span>
            </div>
            <div>
              <div className="flex p-[18px] bg-[#f1f1f1] h-[85vh] popup-tour-wrapper">
                <div className="flex-shrink-0 w-[455px] md:block hidden h-full">
                  <img
                    className="object-cover w-full h-full"
                    srcSet="/images/genting-tour1-2.png 4x"
                    alt=""
                  />
                </div>
                <div className="bg-white p-[15px] flex-1 h-full overflow-auto popup-tour-content">
                  <div className="pb-[16px] mb-[16px] flex items-baseline gap-[5px] border-b-[3px] border-dotted border-[#f1f1f1]">
                    <div>
                      <img srcSet="/icons/map-marker.png 2x" alt="" />
                    </div>
                    <div>
                      <p className="text-[12px] md:text-[20px] text-grey3F font-bold">
                      SINGAPORE – CHECK IN GENTING DREAM
                      </p>
                      <p className="text-[12px] italic">
                        <p className="text-[12px] italic">
                        (Ăn sáng, trưa & tối)
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="px-3" dangerouslySetInnerHTML={{__html: `
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Quý khách dùng bữa sáng và làm thủ tục trả phòng, xe đưa khách tham quan:</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+ <strong>Công viên sư tử biển (Merlion Park)&nbsp;</strong>- đây là biểu tượng của đất nước Singapore.</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+<strong> Tòa nhà Quốc Hội (Parliament House)</strong></span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+<strong> Nhà hát Victoria&nbsp;</strong>– nhà hát cổ kính nhất Singapore.</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+<strong> Nhà hát Esplanade&nbsp;</strong>nổi tiếng với biểu tượng “trái sầu riêng”.</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+ Quý khách mua sắm tại&nbsp;<strong>cửa hàng dầu gió&nbsp;</strong>nổi tiếng độc đáo của Singapore.</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Quý khách dùng bữa trưa - Lẩu nướng tự chọn Korean BBQ</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+ Quý khách mua sắm tại cửa hàng dầu gió nổi tiếng độc đáo và tự do mua sắm tại VivoCity</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;"><strong>17h00</strong> Xe đưa đoàn tới Cảng Marina Bay Cruise. Đoàn làm thủ tục lên Du Thuyền Genting Dream khám phá các dịch vụ cao cấp, tự do thư giãn nghỉ ngơi.</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Quý khách tham dự&nbsp;<strong>SHOW BIỂU DIỄN&nbsp;</strong>đặc sắc tại nhà hát lớn trên Du Thuyền.</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;"><strong>19h00</strong> Du thuyền rời bến</span></p>
                  
                  
                  `}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
