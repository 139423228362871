import React from "react";
import { usePopup } from "../../contexts/popupContext";

export default function PopupTour2Day3() {
  const { showTour2Day3, setShowTour2Day3 } = usePopup();
  return (
    <>
      {showTour2Day3 ? (
        <div className="overlay">
          <div className="max-w-[1022px] w-full relative mb-[50px] top-[20px] left-0 md:left-[50%] md:translate-x-[-50%] popup-days --two">
            <div className="bg-red p-[16px] flex items-center justify-between">
              <p className="text-[12px] md:text-[15px] font-bold text-white">
                NGÀY 3: KUALA LUMPUR - SINGAPORE
              </p>
              <span
                onClick={() => setShowTour2Day3(false)}
                className="cursor-pointer"
              >
                <img srcSet="/icons/popup-close.png 2x" alt="" />
              </span>
            </div>
            <div>
              <div className="flex p-[18px] bg-[#f1f1f1] h-[85vh] popup-tour-wrapper">
                <div className="flex-shrink-0 w-[455px] md:block hidden h-full">
                  <img
                    className="object-cover w-full h-full"
                    src="/images/popup-tour2-3.png"
                    alt=""
                  />
                </div>
                <div className="bg-white p-[15px] flex-1 h-full overflow-auto popup-tour-content">
                  <div className="pb-[16px] mb-[16px] flex items-baseline gap-[5px] border-b-[3px] border-dotted border-[#f1f1f1]">
                    <div>
                      <img srcSet="/icons/map-marker.png 2x" alt="" />
                    </div>
                    <div>
                      <p className="text-[12px] md:text-[20px] text-grey3F font-bold">
                      KUALA LUMPUR - SINGAPORE
                      </p>
                      <p className="text-[12px] italic">
                      (Ăn sáng, trưa & tối)
                      </p>
                    </div>
                  </div>
                  <div className="px-3" dangerouslySetInnerHTML={{__html: `
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;">Quý khách dùng bữa sáng trên du thuyền.</span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>07h00</strong> Du thuyền cập cảng Singapore, Quý khách xuống du thuyền và tham quan:</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Công viên sư tử biển (Merlion Park)&nbsp;</strong>- đây là biểu tượng của đất nước Singapore.</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Tòa nhà Quốc Hội (Parliament House).</strong></span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Nhà hát Victoria – nhà hát cổ kính nhất Singapore. Nơi đây còn là tâm điểm cho nghệ thuật biểu diễn của đảo quốc sư tử ngay từ thời thuộc địa.</strong></span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Nhà hát Esplanade nổi tiếng với biểu tượng “trái sầu riêng”.</strong></span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Quý khách mua sắm tại&nbsp;cửa hàng dầu gió&nbsp;nổi tiếng độc đáo của Singapore.</strong></span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>11h00</strong> Đoàn trở lại du thuyền và trải nghiệm các hoạt động trên du thuyền</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> CÂU LẠC BỘ BÃI BIỂN ZOUK:&nbsp;</strong>Quý khách sẽ khám phá một câu lạc bộ bãi biển ngoài trời tuyệt vời trên biển với những ly cocktail, bể bơi và bàn bi-a, DJ và một màn hình LED khổng lồ</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> PHÒNG THÍ NGHIỆM ESC:&nbsp;</strong>Giải trí đỉnh cao sẽ đưa quý khách vào thế giới ảo mà quý khách chưa từng biết đến. Hãy cảm nhận tàu lượn siêu tốc VR đầy đủ của Finger Coaster, khám phá thế giới dưới nước tuyệt vời với thực tế ảo Icaros… và hơn thế nữa</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> BAR 360:</strong> Phòng chờ 360, ba tầng này mang đến cho bạn cái nhìn về giải trí trực tiếp từ mọi góc độ.&nbsp;</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> NHIỀU LỰA CHỌN ẨM THỰC:</strong> đặc biệt như đồ chay, đồ Ấn Độ. Du thuyền phục vụ ẩm thực Trung Quốc, Nhật Bản, Hàn Quốc, các món ăn Đông Nam Á</span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>20h00</strong> Quý khách tham dự&nbsp;<strong>SHOW BIỂU DIỄN&nbsp;</strong>đặc sắc tại nhà hát lớn trên Du Thuyền.</span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;">Quý khách tự do khám phá các hoạt động trên du thuyền về đêm.</span></p>

                  `}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
