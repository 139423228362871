import React from "react";
import { usePopup } from "../../contexts/popupContext";

export default function PopupTour2Day4() {
  const { showTour2Day4, setShowTour2Day4 } = usePopup();
  return (
    <>
      {showTour2Day4 ? (
        <div className="overlay">
          <div className="max-w-[1022px] w-full relative mb-[50px] top-[20px] left-0 md:left-[50%] md:translate-x-[-50%] popup-days --two">
            <div className="bg-red p-[16px] flex items-center justify-between">
              <p className="text-[12px] md:text-[15px] font-bold text-white">
                NGÀY 4: SINGAPORE - PENANG
              </p>
              <span
                onClick={() => setShowTour2Day4(false)}
                className="cursor-pointer"
              >
                <img srcSet="/icons/popup-close.png 2x" alt="" />
              </span>
            </div>
            <div>
              <div className="flex p-[18px] bg-[#f1f1f1] h-[85vh] popup-tour-wrapper">
                <div className="flex-shrink-0 w-[455px] md:block hidden h-full">
                  <img
                    className="object-cover w-full h-full"
                    src="/images/popup-tour2-4.png"
                    alt=""
                  />
                </div>
                <div className="bg-white p-[15px] flex-1 h-full overflow-auto popup-tour-content">
                  <div className="pb-[16px] mb-[16px] flex items-baseline gap-[5px] border-b-[3px] border-dotted border-[#f1f1f1]">
                    <div>
                      <img srcSet="/icons/map-marker.png 2x" alt="" />
                    </div>
                    <div>
                      <p className="text-[12px] md:text-[20px] text-grey3F font-bold">
                      SINGAPORE - PENANG
                      </p>
                      <p className="text-[12px] italic">
                      (Ăn sáng, trưa & tối)
                      </p>
                    </div>
                  </div>
                  <div className="px-3" dangerouslySetInnerHTML={{__html: `
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;">Quý khách tận hưởng một ngày sôi động với các hoạt động giải trí trên du thuyền:</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> SPA TRỊ LIỆU VÀ THƯ GIÃN TRÊN BIỂN:</strong> kết hợp các kỹ thuật truyền thống của Trung Quốc và bấm huyệt trị liệu. Quý khách sẽ được thư giãn đặc biệt trong khi nhấm nháp trái cây tươi</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> TRẢI NGHIỆM MUA SẮM MIỄN THUẾ:</strong> Quý khách sẽ hòa mình vào mua sắm cao cấp và sẽ được mua thuế và miễn thuế với mức giá hấp dẫn.</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> CÔNG VIÊN NƯỚC TRÊN BIỂN:</strong> Công viên nước trên đại dương với 6 đường trượt. Bên cạnh đó còn có thể tham gia các trò chơi giải trí như Zipline, sân Mini-golf, leo núi nhân tạo, bowling…</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> NHÀ HÁT ZODIAC:</strong> Đón xem những màn trình diễn nghệ thuật xiếc ấn tượng của đội ngũ nghệ sĩ chuyên nghiệp. Các buổi diễn này đều được dàn dựng công phu và chỉn chu từ âm thanh, ánh sáng đến nội dung.&nbsp;</span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>11h00</strong> Du thuyền cập cảng<strong> Penang</strong></span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;">Quý khách dùng bữa trưa tại nhà hàng địa phương. Sau đó đoàn tham quan:</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Đền Kek Lok Si -&nbsp;</strong>hoành tráng mà uy nghiêm trên đồi Air Itam hướng mặt ra biển và đã có tuổi đời khoảng 130 năm. Đền Kek Lok Si còn&nbsp; là nơi hành hương của Phật tử trên khắp thế giới.</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+ Con đường&nbsp;<strong>Kapitan Keling</strong> - con đường hòa thuận - nơi duy nhất trên thế giới tập trung bốn địa điểm tâm linh lâu đời: nhà thờ&nbsp;<strong>Thánh George (Thiên chúa giáo)</strong>,&nbsp;<strong>chùa Quan Âm (Phật giáo)</strong>, đền&nbsp;<strong>Sri Mah Marianmman (Ấn Độ giáo)</strong> và thánh đường&nbsp;<strong>Kapitan Keling (Hồi giáo).</strong></span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Street Art</strong> - Lưu những hình ảnh vô cùng đáng yêu với những bức ảnh vẽ như thật</span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>17h30</strong> Đoàn trở lại du thuyền và dùng bữa tối</span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;">Quý khách thưởng thức&nbsp;<strong>SHOW BIỂU DIỄN</strong> đặc sắc trên Du Thuyền.</span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>21h00</strong> Du thuyền rời bến.</span></p>

                  `}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
