import React from "react";
import { usePopup } from "../../contexts/popupContext";

export default function PopupTour2Day2() {
  const { showTour2Day2, setShowTour2Day2 } = usePopup();
  return (
    <>
      {showTour2Day2 ? (
        <div className="overlay">
          <div className="max-w-[1022px] w-full relative mb-[50px] top-[20px] left-0 md:left-[50%] md:translate-x-[-50%] popup-days --two">
            <div className="bg-red p-[16px] flex items-center justify-between">
              <p className="text-[12px] md:text-[15px] font-bold text-white">
                NGÀY 2: KUALA LUMPUR - CHECK IN DU THUYỀN
              </p>
              <span
                onClick={() => setShowTour2Day2(false)}
                className="cursor-pointer"
              >
                <img srcSet="/icons/popup-close.png 2x" alt="" />
              </span>
            </div>
            <div>
              <div className="flex p-[18px] bg-[#f1f1f1] h-[85vh] popup-tour-wrapper">
                <div className="flex-shrink-0 w-[455px] md:block hidden h-full">
                  <img
                    className="object-cover w-full h-full"
                    src="/images/popup-tour2-2.jpg"
                    alt=""
                  />
                </div>
                <div className="bg-white p-[15px] flex-1 h-full overflow-auto popup-tour-content">
                  <div className="pb-[16px] mb-[16px] flex items-baseline gap-[5px] border-b-[3px] border-dotted border-[#f1f1f1]">
                    <div>
                      <img srcSet="/icons/map-marker.png 2x" alt="" />
                    </div>
                    <div>
                      <p className="text-[12px] md:text-[20px] text-grey3F font-bold">
                      KUALA LUMPUR - CHECK IN DU THUYỀN
                      </p>
                      <p className="text-[12px] italic">
                      (Ăn sáng, trưa & tối)
                      </p>
                    </div>
                  </div>
                  <div className="px-3" dangerouslySetInnerHTML={{__html: `
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>08h00</strong> Quý khách dùng bữa sáng tại khách sạn. Xe đến đón và đưa đoàn đi tham quan</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Chùa Thiên Hậu (Thien Hau Temple)</strong> - Nằm trên đỉnh ngọn đồi Robson, thuộc thủ đô Kuala Lumpur. Với diện tích chùa lên tới 6760m2, chùa Bà Thiên Hậu được đánh giá là ngôi chùa đẹp và lớn nhất Đông Nam Á.</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+ Chụp hình lưu niệm tại&nbsp;<strong>Tháp Đôi (Twin Towers).</strong></span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Cung điện Hoàng gia (Royal Palace)</strong> - Cung điện được xây dựng với hướng nhìn ra sông kết hợp với địa thế phong thủy độc đáo "tựa sơn- hứng thủy" đã tạo nên một vẻ đẹp rất riêng.</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Đài tượng niệm Quốc gia (National Monument)</strong> - Một trong những bức tượng đồng lớn nhất thế giới, cao 15.5 m.</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Quảng trường Độc lập (Independence Square).</strong></span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>12h00</strong> Đoàn ăn trưa tại nhà hàng địa phương. Sau đó đoàn đi tham quan:</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+ Tham quan&nbsp;<strong>Cửa hàng Socola - Bery's</strong> &amp; đặc sản địa phương</span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>15h00</strong> Sau khi tham quan, xe đưa đoàn di chuyển ra cảng&nbsp;<strong>Port Klang</strong> tiến hành làm thủ tục lên du thuyền</span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>18h00</strong> Quý khách dùng bữa tối và nghỉ ngơi trên du thuyền</span></p>


                  `}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
