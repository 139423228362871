import React from "react";
import { usePopup } from "../../contexts/popupContext";

export default function PopupTour2Day5() {
  const { showTour2Day5, setShowTour2Day5 } = usePopup();
  return (
    <>
      {showTour2Day5 ? (
        <div className="overlay">
          <div className="max-w-[1022px] w-full relative mb-[50px] top-[20px] left-0 md:left-[50%] md:translate-x-[-50%] popup-days --two">
            <div className="bg-red p-[16px] flex items-center justify-between">
              <p className="text-[12px] md:text-[15px] font-bold text-white">
                NGÀY 5: KUALA LUMPUR - VIỆT NAM
              </p>
              <span
                onClick={() => setShowTour2Day5(false)}
                className="cursor-pointer"
              >
                <img srcSet="/icons/popup-close.png 2x" alt="" />
              </span>
            </div>
            <div>
              <div className="flex p-[18px] bg-[#f1f1f1] h-[85vh] popup-tour-wrapper">
                <div className="flex-shrink-0 w-[455px] md:block hidden h-full">
                  <img
                    className="object-cover w-full h-full"
                    src="/images/popup-tour2-5.png"
                    alt=""
                  />
                </div>
                <div className="bg-white p-[15px] flex-1 h-full overflow-auto popup-tour-content">
                  <div className="pb-[16px] mb-[16px] flex items-baseline gap-[5px] border-b-[3px] border-dotted border-[#f1f1f1]">
                    <div>
                      <img srcSet="/icons/map-marker.png 2x" alt="" />
                    </div>
                    <div>
                      <p className="text-[12px] md:text-[20px] text-grey3F font-bold">
                      KUALA LUMPUR - VIỆT NAM
                      </p>
                      <p className="text-[12px] italic">
                      (Ăn sáng & trưa)
                      </p>
                    </div>
                  </div>
                  <div className="px-3" dangerouslySetInnerHTML={{__html: `
<p><span style="background-color:#ffffff;color:#000000;font-size:14px;"><strong>08h00&nbsp;</strong>Quý khách dùng bữa sáng trên du thuyền và tự do nghỉ ngơi.</span></p>
<p><span style="background-color:#ffffff;color:#000000;font-size:14px;"><strong>11h00&nbsp;</strong>Du thuyền cập cảng, Quý khách làm thủ tục check out trên du thuyền. Xe đưa đoàn ra sân bay làm thủ tục về Việt Nam trên chuyến bay dự kiến</span><span style="background-color:#ffffff;color:#0000ff;font-size:14px;">&nbsp;<strong>MH 766 KULSGN (17:35 - 18:50)</strong>.</span></p>
<p style="text-align:center;"><span style="background-color:#ffffff;color:#000000;font-size:14px;"><strong>Chia tay Quý khách. Hẹn gặp lại quý khách!</strong></span></p>

                  `}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
