import React from "react";
import { usePopup } from "../../contexts/popupContext";

export default function PopupTour1Day4() {
  const { showTour1Day4, setShowTour1Day4 } = usePopup();
  return (
    <>
      {showTour1Day4 ? (
        <div className="overlay">
          <div className="max-w-[1022px] w-full relative mb-[50px] top-[20px] left-0 md:left-[50%] md:translate-x-[-50%] popup-days --two">
            <div className="bg-red p-[16px] flex items-center justify-between">
              <p className="text-[12px] md:text-[15px] font-bold text-white">
                NGÀY 4: DU THUYỀN - SINGAPORE - VIỆT NAM
              </p>
              <span
                onClick={() => setShowTour1Day4(false)}
                className="cursor-pointer"
              >
                <img srcSet="/icons/popup-close.png 2x" alt="" />
              </span>
            </div>
            <div>
              <div className="flex p-[18px] bg-[#f1f1f1] h-[85vh] popup-tour-wrapper">
                <div className="flex-shrink-0 w-[455px] md:block hidden h-full">
                  <img
                    className="object-cover w-full h-full"
                    srcSet="/images/popup-tour1-4.png 4x"
                    alt=""
                  />
                </div>
                <div className="bg-white p-[15px] flex-1 h-full overflow-auto popup-tour-content">
                  <div className="pb-[16px] mb-[16px] flex items-baseline gap-[5px] border-b-[3px] border-dotted border-[#f1f1f1]">
                    <div>
                      <img srcSet="/icons/map-marker.png 2x" alt="" />
                    </div>
                    <div>
                      <p className="text-[12px] md:text-[20px] text-grey3F font-bold">
                      DU THUYỀN - SINGAPORE - VIỆT NAM
                      </p>
                      <p className="text-[12px] italic">
                      (Ăn sáng)
                      </p>
                    </div>
                  </div>
                  <div className="px-3" dangerouslySetInnerHTML={{__html: `
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Quý khách dậy sớm ngắm bình minh trên du thuyền, tự do ăn sáng tại các nhà hàng.</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;"><strong>08h30</strong> Quý khách làm thủ tục xuống Cảng.</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;">Xe đưa Quý khách ra sân bay, Quý khách tự do tham quan:</span></p>
                  <p style="margin-left:40px;"><span style="background-color:#ffffff;color:#081c36;font-size:14px;">+<strong> Jewel Changi</strong> được thiết kế bởi kiến trúc sư người Canada gốc Israel Moshe Safdie. Ông cũng là người thiết kế khu nghỉ dưỡng Marina Bay Sands</span></p>
                  <p><span style="background-color:#ffffff;color:#081c36;font-size:14px;"><strong>11h00</strong> Quý khách làm thủ tục đón chuyến bay dự kiến <span style="background-color:#ffffff;color:#0000ff;font-size:14px;"><strong>VN 650 SINSGN (13:10 – 14:40) về lại Việt Nam.</strong></span></span></p>
                  <p style="text-align:center;"><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>Chia tay và hẹn gặp lại quý khách!</strong></span></p>
                  
                  
                  `}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
