import React from "react";
import { usePopup } from "../../contexts/popupContext";

export default function PopupTour2Day1() {
  const { showTour2Day1, setShowTour2Day1 } = usePopup();
  return (
    <>
      {showTour2Day1 ? (
        <div className="overlay">
          <div className="max-w-[1022px] w-full relative mb-[50px] top-[20px] left-0 md:left-[50%] md:translate-x-[-50%] popup-days --two">
            <div className="bg-red p-[16px] flex items-center justify-between">
              <p className="text-[12px] md:text-[15px] font-bold text-white">
                NGÀY 1: HỒ CHÍ MINH - KUALA LUMPUR
              </p>
              <span
                onClick={() => setShowTour2Day1(false)}
                className="cursor-pointer"
              >
                <img srcSet="/icons/popup-close.png 2x" alt="" />
              </span>
            </div>
            <div>
              <div className="flex p-[18px] bg-[#f1f1f1] h-[85vh] popup-tour-wrapper">
                <div className="flex-shrink-0 w-[455px] md:block hidden h-full">
                  <img
                    className="object-cover w-full h-full"
                    src="/images/popup-tour2-1.png"
                    alt=""
                  />
                </div>
                <div className="bg-white p-[15px] flex-1 h-full overflow-auto popup-tour-content">
                  <div className="pb-[16px] mb-[16px] flex items-baseline gap-[5px] border-b-[3px] border-dotted border-[#f1f1f1]">
                    <div>
                      <img srcSet="/icons/map-marker.png 2x" alt="" />
                    </div>
                    <div>
                      <p className="text-[12px] md:text-[20px] text-grey3F font-bold">
                      HỒ CHÍ MINH - KUALA LUMPUR
                      </p>
                      <p className="text-[12px] italic">
                        (Ăn tối)
                      </p>
                    </div>
                  </div>

                  <div className="px-3" dangerouslySetInnerHTML={{__html: `
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>08h00</strong> Quý khách đến sân bay quốc tế, làm thủ tục khởi hành đi Malaysia trên chuyến bay dự kiến<span style="background-color:#ffffff;color:#0000ff;font-size:14px;"><strong> MH 751 SGNKUL (11:00 - 13:55)</strong></span></span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;"><strong>13h55</strong> Tới sân bay quốc tế Kuala Lumpur, làm thủ tục nhập cảnh. Xe và hướng dẫn viên địa phương đưa Quý khách tham quan:</span></p>
<p style="margin-left:40px;"><span style="background-color:#ffffff;color:#131313;font-size:14px;">+<strong> Khu PUTRAJAYA -&nbsp;</strong>Trung Tâm Hành Chính mới (NEW CITY) của Malaysia</span></p>
<p><span style="background-color:#ffffff;color:#131313;font-size:14px;">Xe đưa đoàn dùng bữa tối và làm thủ tục nhận phòng khách sạn nghỉ ngơi.</span></p>

                  `}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
